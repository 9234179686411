import React, { useState } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import SearchWrapper from './Search.style';

const Search = () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPost(sort: { order: DESC, fields: date }) {
          edges {
            node {
              id
              title
              path
            }
          }
        }
      }
    `}
    render={data => {
      const searchData = data.allWordpressPost.edges;
      return <SearchInput items={searchData} />;
    }}
  />
);

const SearchInput = ({ items }) => {
  const [state, setState] = useState({
    searchString: '',
    data: items,
    visibility: false,
  });

  const handleSearch = e => {
    setState({
      ...state,
      searchString: e.target.value,
    });
  };

  const handleOnBlur = () => {
    setState({
      ...state,
      visibility: false,
    });
  };

  const handleOnFocus = () => {
    setState({
      ...state,
      visibility: true,
    });
  };

  let { data } = state;
  const { searchString } = state;

  if (searchString.length > 0) {
    data = data.filter(function(item) {
      return item.node.title.toLowerCase().match(searchString);
    });
  }

  return (
    <SearchWrapper
      className={`search ${state.visibility ? 'show-results' : ''}`}
    >
      <input
        type="text"
        value={searchString}
        onChange={handleSearch}
        placeholder="Zoeken"
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      {searchString.length > 0 && data.length !== 0 ? (
        <ul className="search-results">
          {data.map(searchItem => {
            const regex = new RegExp(searchString, 'gi');
            const searchTitle = searchItem.node.title.replace(
              regex,
              `<span className="hl" style="color: black; font-weight: 600; background-color: #FFF689">${searchString}</span>`
            );
            return (
              <li key={searchItem.node.id}>
                <Link
                  to={searchItem.node.path}
                  dangerouslySetInnerHTML={{
                    __html: searchTitle,
                  }}
                />
              </li>
            );
          })}
        </ul>
      ) : (
        <>
          {searchString.length > 0 && (
            <ul className="search-results no-results">
              <li>Niets gevonden.</li>
            </ul>
          )}
        </>
      )}
    </SearchWrapper>
  );
};

export default Search;
