import styled from 'styled-components';
import { themeGet } from 'styled-system';

const HeaderWrapper = styled.header`
  width: 100%;

  @media print {
    display: none;
  }
`;

export const Navbar = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  height: 72px;
  padding: 0px 25px;
  background-color: ${themeGet('color.1')};

  ul {
    margin: 0;
  }
`;

export const NavMainMenu = styled.ul`
  width: 100%;
  display: flex;
  padding: 0px 25px;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: 992px) and (max-width: 1366px) {
    width: calc(100% - 350px);
    padding: 0;
    overflow-x: hidden;
  }

  @media only screen and (max-width: 991px) {
    display: none;
  }

  li {
    list-style: none;
    a {
      color: ${themeGet('color.2')};
      display: flex;
      height: 72px;
      padding: 0 10px;
      font-size: 16px;
      align-items: center;
      font-family: 'Inter', sans-serif;
      &.active,
      &:hover {
        color: ${themeGet('color.3')};
      }
      svg {
        margin-left: 8px;
        transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
      }
    }

    /* submenu style goes here */
    ul.submenu {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      left: 0;
      top: 72px;
      height: 72px;
      padding: 0px 25px;
      background-color: ${themeGet('color.5')};
      opacity: 0;
      visibility: hidden;
      transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          svg {
            width: 16px;
            height: 16px;
            transform: rotate(0);
          }
        }
        &.parent-item {
          &:hover {
            > a {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
        ul.submenu {
          top: 144px;
          opacity: 0;
          visibility: hidden;
          background-color: ${themeGet('color.6')};
        }
        &:hover {
          a {
            background-color: ${themeGet('color.6')};
          }
          > ul.submenu {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    /* end of submenu style */

    &:hover {
      > a {
        background-color: ${themeGet('color.5')};
        svg {
          transform: rotate(180deg);
        }
      }
      > ul.submenu {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;

export const NavbarRightWrapper = styled.div`
  display: flex;
  align-items: center;

  .hamburg-menu {
    display: none;
    min-width: 52px;
    cursor: pointer;

    > span {
      display: block;
      width: 100%;
      height: 2px;
      margin: 3px 0;
      float: right;
      border-radius: 4px;
      background-color: ${themeGet('color.3')};
      transition: all 0.3s ease;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        width: calc(100% - 8px);
        margin-bottom: 0;
      }
    }

    &:focus,
    &:hover {
      outline: none;
      > span {
        &:last-child {
          width: 100%;
        }
      }
    }

    &:focus {
      > span {
        &:first-child {
          transform: rotate(45deg);
          transform-origin: 9px 50%;
        }
        &:nth-child(2) {
          display: none;
        }
        &:last-child {
          width: 100%;
          transform: rotate(-45deg);
          transform-origin: 10px 50%;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .search {
      > input[type='text'] {
        min-width: 200px;
        text-align: left;
      }
    }

    .hamburg-menu {
      display: block;
      margin-left: 30px;
    }
  }

  @media only screen and (max-width: 567px) {
    .hamburg-menu {
      margin-left: 15px;
    }
  }

  @media only screen and (max-width: 480px) {
    .search {
      > input[type='text'] {
        min-width: 120px;
      }
    }
  }

  @media only screen and (max-width: 320px) {
    .search {
      > input[type='text'] {
        min-width: 100px;
      }
    }

    .hamburg-menu {
      margin-left: 15px;
    }
  }
`;

export const Drawer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  display: flex;
  pointer-events: none;
  justify-content: flex-end;
  transition: all 0.3s ease;

  .drawer-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.4s 0.2s ease;
  }

  .drawer-content {
    width: 300px;
    padding: 30px 35px 40px;
    margin-right: -100%;
    box-sizing: border-box;
    color: ${themeGet('color.2')};
    background-color: ${themeGet('color.7')};
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.4);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    pointer-events: all;
    transition: all 0.4s ease;

    @media only screen and (max-width: 320px) {
      width: 250px;
    }

    button.close {
      opacity: 0;
      width: 35px;
      height: 35px;
      background: ${themeGet('color.3')};
      color: ${themeGet('color.7')};
      position: absolute;
      cursor: pointer;
      border-radius: 50%;
      left: -17px;
      top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      line-height: 1;
      padding: 0;
      min-width: inherit;
      transition: all 200ms 0.3s ease;

      &:hover,
      &.active {
        opacity: 0.9;
      }
    }

    .search {
      input {
        width: 100%;
        background-color: #020202;
        padding: 1.1em;
        border-color: #000;
      }

      ul.search-results {
        width: 100%;
        z-index: 9;
      }

      @media only screen and (min-width: 569px) {
        display: none;
      }
    }

    .main-menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;

      li {
        width: 100%;
        position: relative;

        a {
          height: 52px;
          padding: 0;
          justify-content: flex-start;
        }

        ul.submenu {
          height: auto;
          position: absolute;
          flex-direction: column;
          top: 46px;
          z-index: 9;
          padding: 0;

          li {
            a {
              padding: 0 20px;
            }

            ul.submenu {
              padding: 15px 0 27px;
              li {
                a {
                  padding: 0 35px;
                }
              }
            }
          }
        }
      }
    }
  }

  ul {
    li.parent-item {
      > a {
        pointer-events: none;
      }
    }
  }

  &.open {
    .drawer-content {
      margin-right: 0;
      button.close {
        opacity: 1;
      }
    }
    .drawer-overlay {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
    }
  }
`;

export const SmNone = styled.div`
  @media only screen and (max-width: 568px) {
    display: none;
  }
`;

export default HeaderWrapper;
