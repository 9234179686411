import React from 'react';
import { Link } from 'gatsby';
import { FaExternalLinkAlt } from 'react-icons/fa';

const SubmenuHasSubmenu = ({ items }) => (
  <ul className="submenu">
    {items.map(item => {
      let menuUrl = item.url;

      // menu for external link
      if (
        item.url.includes('https') &&
        !item.url.includes('https://nov.covey.cloud')
      ) {
        return (
          <li
            key={item.object_id}
            className={item.wordpress_children ? 'parent-item' : 'menu-item'}
          >
            <a href={menuUrl} target="_blank" rel="noopener noreferrer">
              {item.title}
              <FaExternalLinkAlt />
            </a>
          </li>
        );
      }

      // menu link for without slug
      if (
        item.url.includes('https') &&
        item.url.includes('https://nov.covey.cloud')
      ) {
        menuUrl = new URL(item.url).pathname;
      }

      return (
        <li
          key={item.object_id}
          className={item.wordpress_children ? 'parent-item' : 'menu-item'}
        >
          <Link to={menuUrl} activeClassName="active">
            {item.title}
          </Link>
        </li>
      );
    })}
  </ul>
);

export default SubmenuHasSubmenu;
