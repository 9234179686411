import styled from 'styled-components';
import { themeGet } from 'styled-system';

const LogoWrapper = styled.div`
  a {
    display: inline-flex;
    color: ${themeGet('color.2')};
  }
`;

export default LogoWrapper;
