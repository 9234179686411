import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Logo from '../Logo/Logo';
import Search from '../Search/Search';
import MainMenu from './MainMenu';
import MenuDrawer from './MenuDrawer';
import HeaderWrapper, {
  Navbar,
  NavbarRightWrapper,
  SmNone,
} from './Nav.style.js';

const Nav = () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              id
              name
              slug
              items {
                url
                title
                object_id
                object_slug
                wordpress_children {
                  url
                  title
                  object_id
                  object_slug
                  wordpress_children {
                    url
                    title
                    object_id
                    object_slug
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const menuItems =
        data.allWordpressWpApiMenusMenusItems.edges[1].node.items;
      return (
        <HeaderWrapper className="header-area">
          <Navbar className="navbar">
            {/* Start logo area */}
            <Logo />
            {/* End of logo area */}

            {/* Start main menu */}
            <MainMenu items={menuItems} />
            {/* End of main menu */}

            <NavbarRightWrapper>
              {/* Search component */}
              <SmNone>
                <Search />
              </SmNone>
              {/* End of search component */}

              {/* Lid worden page link */}
              <Link
                to="/lidworden"
                className="button primary "
                style={{ marginLeft: '10px' }}
              >
                Lid Worden
              </Link>
              {/* Endo of lid worden page link */}

              {/* Responsive Menu */}
              <MenuDrawer
                search={<Search />}
                menu={<MainMenu items={menuItems} />}
              />
              {/* End of Responsive Menu */}
            </NavbarRightWrapper>
          </Navbar>
        </HeaderWrapper>
      );
    }}
  />
);

export default Nav;
