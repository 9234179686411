import React, { Fragment } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'gatsby';
import SubmenuHasSubmenu from './SubmenuHasSubmenu';

const SubMenu = ({ items }) => (
  <Fragment>
    {items && (
      <ul className="submenu">
        {items.map(item => {
          let menuUrl = item.url;

          // menu for external link
          if (
            item.url.includes('https') &&
            !item.url.includes('https://nov.covey.cloud')
          ) {
            return (
              <li
                key={item.object_id}
                className={
                  item.wordpress_children ? 'parent-item' : 'menu-item'
                }
              >
                <a href={menuUrl} target="_blank" rel="noopener noreferrer">
                  {item.title}
                  {item.wordpress_children ? (
                    <IoIosArrowDown />
                  ) : (
                    <FaExternalLinkAlt />
                  )}
                </a>
                {/* If submenu has submenu */}
                {item.wordpress_children && (
                  <SubmenuHasSubmenu items={item.wordpress_children} />
                )}
              </li>
            );
          }

          // menu link for without slug
          if (
            item.url.includes('https') &&
            item.url.includes('https://nov.covey.cloud')
          ) {
            menuUrl = new URL(item.url).pathname;
          }

          return (
            <li
              key={item.object_id}
              className={item.wordpress_children ? 'parent-item' : 'menu-item'}
            >
              <Link to={menuUrl} activeClassName="active">
                {item.title}
                {item.wordpress_children && <IoIosArrowDown />}
              </Link>
              {/* If submenu has submenu */}
              {item.wordpress_children && (
                <SubmenuHasSubmenu items={item.wordpress_children} />
              )}
            </li>
          );
        })}
      </ul>
    )}
  </Fragment>
);

export default SubMenu;
