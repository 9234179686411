import React from 'react';
import { Link } from 'gatsby';
import { IoIosArrowDown } from 'react-icons/io';
import { FaExternalLinkAlt } from 'react-icons/fa';
import SubMenu from './SubMenu';
import { NavMainMenu } from './Nav.style';

const MainMenu = ({ items }) => (
  <NavMainMenu className="main-menu">
    {items.map(item => {
      let menuUrl = item.url;

      // menu for external link
      if (
        item.url.includes('https') &&
        !item.url.includes('https://nov.covey.cloud')
      ) {
        return (
          <li
            key={item.object_id}
            className={item.wordpress_children ? 'parent-item' : 'menu-item'}
          >
            <a href={menuUrl} target="_blank" rel="noopener noreferrer">
              {item.title}
              {item.wordpress_children ? (
                <IoIosArrowDown />
              ) : (
                <FaExternalLinkAlt size="0.7em" />
              )}
            </a>
            {/* If has submenu */}
            <SubMenu items={item.wordpress_children} />
          </li>
        );
      }

      // menu link for without slug
      if (
        item.url.includes('https') &&
        item.url.includes('https://nov.covey.cloud')
      ) {
        menuUrl = new URL(item.url).pathname;
      }

      return (
        <li
          key={item.object_id}
          className={item.wordpress_children ? 'parent-item' : 'menu-item'}
        >
          <Link to={menuUrl} activeClassName="active">
            {item.title}
            {item.wordpress_children && <IoIosArrowDown />}
          </Link>
          {/* If has submenu */}
          <SubMenu items={item.wordpress_children} />
        </li>
      );
    })}
  </NavMainMenu>
);

export default MainMenu;
