import styled from 'styled-components';
import { themeGet } from 'styled-system';

const SearchWrapper = styled.div`
  position: relative;

  input[type='text'] {
    border: 1px solid ${themeGet('color.7')};
    background-color: ${themeGet('color.7')};
    border-radius: 5px;
    min-width: 100px;
    padding: 0.8em 1em;
    font-size: 0.7rem;
    font-family: 'Inter', sans-serif;
    color: ${themeGet('color.2')};
    box-sizing: border-box;
    text-align: center;

    &:focus {
      outline: 0;
    }
  }

  ul.search-results {
    position: absolute;
    width: 200px;
    min-height: 60px;
    max-height: 270px;
    top: 40px;
    right: 0;
    background-color: #fff;
    list-style: none;
    padding: 10px 0;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

    @media only screen and (max-width: 991px) {
      left: 0;
      right: auto;
      top: 70px;
    }

    li {
      color: #242424;
      font-size: 15px;
      line-height: 23px;
      font-weight: 500;

      a {
        color: #242424;
        display: block;
        padding: 10px 15px;
        border-bottom: 1px solid #e6e6e6;

        &:hover,
        &:focus {
          color: #000;
        }
      }

      &:first-child {
        a {
          padding-top: 0;
        }
      }

      &:last-child {
        a {
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }

    &.no-results {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.show-results {
    .search-results {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export default SearchWrapper;
