import React, { Fragment, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { Drawer } from './Nav.style';

const MenuDrawer = ({ menu, search }) => {
  const [state, setState] = useState(false);

  const handleMenuDrawer = () => {
    setState(!state);
  };

  const closeDrawer = () => {
    setState(false);
  };

  return (
    <Fragment>
      <button type="button" className="hamburg-menu" onClick={handleMenuDrawer}>
        <span />
        <span />
        <span />
      </button>

      <Drawer className={`drawer ${state ? 'open' : ''}`}>
        <div className="drawer-overlay" onClick={closeDrawer} />
        <div className="drawer-content">
          <button type="button" className="close" onClick={closeDrawer}>
            <IoIosClose />
          </button>
          {search}
          {menu}
        </div>
      </Drawer>
    </Fragment>
  );
};

export default MenuDrawer;
