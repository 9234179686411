import React, { Component, Fragment, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ContextProvider, ThemeContext } from '../context/themeContext';
import themes from '../theme';
import Nav from '../components/Nav/Nav';
import Footer from '../components/Footer/Footer';
import GlobalStyle from '../components/Global.style.js';
import LayoutWrapper from './layout.style.js';

class Layout extends Component {
  // TODO This should be a hook eventually, once it's supported: https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
  componentDidCatch(error, errorInfo) {
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  render() {
    const { children } = this.props;

    return (
      <ContextProvider>
        <InnerLayout>{children}</InnerLayout>
      </ContextProvider>
    );
  }
}

const InnerLayout = ({ children }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <ThemeProvider
      theme={themeContext.contextState === 'dark' ? themes.light : themes.dark}
    >
      <Fragment>
        <ParallaxProvider>
          <Helmet>
            <script
              async
              defer
              src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAAT7gq27V_b2uAQZgtxTNqVM9FRPEZRk0&libraries=places&callback=initMap"
            ></script>
          </Helmet>
          <GlobalStyle />
          <LayoutWrapper>
            <Nav />
            <main className="layout">{children}</main>
            <Footer />
          </LayoutWrapper>
        </ParallaxProvider>
      </Fragment>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
